const state = {
  analyInfo:{
    schoolExamAnalysisInfoId:"", //分析考试id
    analysisOneCourseId:"", //分析课程
    analyTitle:"", //分析标题
  },
  grade: {},
  exam: {},
  analyId: null,
  analyTitle: null,
  analyInfoData: {},
  isLoading: false
}

const mutations = {
  SET_analyInfo: (state, data) => {
    state.analyInfo = data
  },
  SET_kemubygrade: (state,data) => {
    localStorage.setItem('grade', data) 
    state.grade = data
  },
  SET_exam: (state,data) => {
    localStorage.setItem('exam', data) 
    state.exam = data
  },
  SET_analyId(state, id) {
    state.analyId = id;
  },
  SET_analyTitle(state, title) {
    state.analyTitle = title;
  },
  SET_analyInfoData(state, row) {
    state.analyInfoData = row;
  },
  SET_status: (state,data) => {
    state.isLoading = data
  }
}

const actions = {
  setAnalyTitle({ commit }, title) {
    commit('SET_analyTitle', title);
  }
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}