import Cookies from 'js-cookie'

const TokenKey = 'x-jt-admin-token'
const MenuKey = 'x-jt-admin-Menu'
const TokenAdminKey = 'x-jt-adminUser-token'
export function getToken() {
  return Cookies.get(TokenKey)
}
export function getAdminToken() {
  return Cookies.get(TokenAdminKey)
}

export function setToken(token) {
  const expires = new Date(new Date(new Date().getTime() + 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0))
  return Cookies.set(TokenKey, token, { expires: expires })
}

export function setAdminToken(token) {
  const expires = new Date(new Date(new Date().getTime() + 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0))
  return Cookies.set(TokenAdminKey, token, { expires: expires })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getMenu() {
  return Cookies.get(MenuKey)
}

export function setMenu(menu=[]) {
  return Cookies.set(MenuKey, menu)
}

export function removeMenu() {
  return Cookies.remove(MenuKey)
}
