const state = {
  pageNum:1, //页面数
  pageTitle:'', //页面标题
  gradeName:'', //年级名称
  courseName: '', //课程名称
  pageCheckType: '', //阅卷方式
  pageLyType: '', //布局方式
  questionLists:[], //题目列表
  pagesQueForUIData:[], //页面UI数据
}

const mutations = {
  SET_QuestionLists: (state, questionList) => {
    state.questionLists = questionList
  },
  SET_pagesQueForUIData: (state, pagesQueForUIData) => {
    state.pagesQueForUIData = pagesQueForUIData
  },
}

const actions = {
  // 
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}