import {
  removeToken,
  getAdminToken,
  removeMenu
} from '@/utils/auth'
import router, {
  resetRouter
} from '@/router'
import user from '@/http/api/user'

const state = {
  adminToken: getAdminToken(),
  name: '',
  avatar: '',
  userName: '',
  roleId: '',
  buttons: [],
  adminInfo:{},
}

const mutations = {
  SET_AdMINTOKEN: (state, adminToken) => {
    state.adminToken = adminToken
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_userName: (state, userName) => {
    state.userName = userName
  },
  SET_AdMIN_INFO(state, adminInfo) {
    state.adminInfo = adminInfo;
  },
  // roleId orderMgmt加权限
  SET_roleId: (state, roleId) => {
    state.roleId = roleId
  },
  SET_BUTTONS: (state, buttons) => {
    state.buttons = buttons
  }
}
let logoutInProgress = false;
const actions = {
  SET_AdMINTOKEN: ({
    commit
  }, adminToken) => {
    commit('SET_AdMINTOKEN', adminToken)
  },
  adminLogin({
    commit
  }, adminInfo) {
    const {
      username,
      password
    } = adminInfo
    return new Promise((resolve, reject) => {
      user.login1({
        username: username.trim(),
        password: password
      }).then(response => {
        // console.log('action login response')
        const {
          data
        } = response
        // console.log('data.accessToken' + data.accessToken)
        commit('SET_AdMINTOKEN', data.accessToken);
        // setToken(data.accessToken)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
    
  },

  adminLogout({commit}) {
    if (logoutInProgress) {
      return Promise.resolve();
    }
    logoutInProgress = true;
    return new Promise((resolve) => {
      commit('SET_AdMINTOKEN', '')
      removeToken()
      router.push('/admin/login').then(() => {
        logoutInProgress = false; // 登出完成，允许后续登出
        resolve();
      });
    });
  },
  resetAdminToken({commit}){
    return new Promise(resolve => {
      commit('SET_AdMINTOKEN', '')
      removeToken()
      resolve()
    })
  }
}
const getters = {
  adminInfo: (state) => state.adminInfo
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
