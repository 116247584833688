import { get, post,put,deleteRequest, service1 } from '../common/http';
import base from '../common/base';
import axios from 'axios' // 引用axios

const reviewBlock = {
  //获取试卷信息
  getPaperInfo(id){
    return get(base.prefix + '/api/v1/myExamCourse/school-exam-course-relation-infos/' + id)
  },
  // 新增批阅块
  addNewReviewBlock(data){
    return post(base.prefix + '/api/v1/school-answer-paintings', data)
  },
    // 获取批阅块列表
    getReviewBlockList(data){
        return get(base.prefix + '/api/v1/school-answer-paintings', data)
    },
    // 编辑批阅块
    editReviewBlock(data){
        return post(base.prefix + '/api/v1/school-answer-paintings-update', data)
    },
    // 删除批阅块
    deleteReviewBlock(data){
        return deleteRequest(base.prefix + '/api/v1/school-answer-paintings', data,'arr')
    },
      //获取采分点列表
  getSamplingPointList(data){
    return get(base.prefix + '/api/v1/school-answer-samplings', data)
  },
  //新增采分点
  addSamplingPoint(data){
    return post(base.prefix + '/api/v1/school-answer-samplings', data)
  },
  //删除采分点
  deleteSamplingPoint(data){
    return deleteRequest(base.prefix + '/api/v1/school-answer-samplings', data, "arr")
  },
  //编辑采分点
  editSamplingPoint(data){
    return put(base.prefix + '/api/v1/school-answer-samplings-update', data)
  },
  //获取批阅块详情
  getReviewBlockDetail(id){
    return get(base.prefix + '/api/v1/school-answer-paintings/' +  id)
  },
    //获取标准答案录入列表
    getStandardAnswerEnterList(data){
      return get(base.prefix + '/api/v1/school-answer-enters',data)
    },
    //编辑标准答案录入
    editStandardAnswerEnter(data){
      return post(base.prefix + '/api/v1/school-answer-enters-update',data)
    },
    //获取标准答案录入列表
    getStandardAnswerScore(data){
      return get(base.prefix + '/api/v1/calculateScore',data)
    },
    //获取知识点列表
    getKnowledgePointList(data){
      return get(base.prefix + '/api/v1/school-knowledge-pointss',data)
    },
    //编辑知识点
    editKnowledgePoint(data){
      return put(base.prefix + '/api/v1/school-knowledge-pointss-update',data)
    },
    //标准答案导出
    standardAnswerExport(data){
    const url = base.prefix + '/api/v1/standardAnswerExport';
    return new Promise((resolve, reject) => {
      service1.post(url, data, {responseType: 'blob' }).then(
          response => {
              resolve(response)
          },
          err => {
              reject(err)
          }
      )
  })
  },
    //导入标准答案
    importStandardAnswer(data){
      const url = base.prefix + `/api/v1/standardAnswerImport`
      return new Promise((resolve, reject) => {
        service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
          response => {
            resolve(response.data)
          },
          err => {
            reject(err)
          }
        )
      })
    },
    //导入知识点
    importKnowledgePotin(data){
      const url = base.prefix + `/api/v1/knowledgePointssImport`
      return new Promise((resolve, reject) => {
        service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
          response => {
            resolve(response.data)
          },
          err => {
            reject(err)
          }
        )
      })
    },
    //知识点导出
    exportKnowledgePotin(data){
      const url = base.prefix + '/api/v1/knowledgePointssExport';
      return new Promise((resolve, reject) => {
        service1.post(url, data, {responseType: 'blob' }).then(
            response => {
                resolve(response)
            },
            err => {
                reject(err)
            }
        )
    })
    },
}

export default reviewBlock