import user from './api/user'
import school from './api/school'
import analysis from './api/analysis'
import analysisReport from './api/analysisReport'
import exam from './api/exam'
import answerCard from './api/answerCard'
import reviewBlock from './api/reviewBlock'
import markingTask from './api/markingTask'
export default {
    user,  
    school,
    analysis,
    analysisReport,
    exam,
    answerCard,
    reviewBlock,
    markingTask
}