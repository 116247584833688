import {get,post,deleteRequest,put} from '../common/http'
import base from '../common/base'
const answerCard = {
  
  getListBy(data) {
    return get(base.prefix+'/api/v1/student/stuList', data)
  },
  getAnswerCardList(data){
    return get(base.prefix+'/api/v1/card/answerList', data)
  },
  //删除
  deleteAnswerCard(data){
    return deleteRequest(base.prefix+'/api/v1/card/delAnswer/'+data.id)
  },
  //新增
  insertAnswerCard(data){
    return post(base.prefix+'/api/v1/card/addAnswer', data)
  },
  //详细
  answerCardDetail(data){
    return get(base.prefix+'/api/v1/card/answerInfo', data)
  },
  //更新
  updateAnswerCard(data){
    return put(base.prefix+'/api/v1/card/updateAnswer', data)
  },
  
}

export default answerCard