import { get, post,put,deleteRequest,service1 } from '../common/http';
import base from '../common/base';

const markingTask = {
 // 获取阅卷任务列表
 listReadingTask(data) {
    return get(base.prefix + '/api/v1/correct/listReadingTask', data)
  },
  //获取正评批阅包数据
  getPackage(data) {
    return get(base.prefix + '/api/v1/correct/getPackage', data)
  },
  //获取-详情-进入批阅
  schoolExamMarkingById(data) {
    return get(base.prefix + '/api/v1/correct/schoolExamMarkingById/'+data.id,)
  },
  //复查
  getReviewPageList(data) {
    return get(base.prefix + '/api/v1/correct/getReviewPageList',data)
  },
  //回评
  getAnswerPageList(data) {
    return get(base.prefix + '/api/v1/correct/getAnswerPageList',data)
  },
 //结束批阅
 finishRead(data) {
    return post(base.prefix + '/api/v1/correct/finishRead', data)
  },
  //标记异常试卷
  signUnusual(data) {
    return post(base.prefix + '/api/v1/correct/signUnusual', data)
  },
  
 
  
  
  
}

export default markingTask