import { get, post, put, deleteRequest } from '../common/http';
import base from '../common/base';
const exam = {
  // 获取考试类型
  getExamTypes(data) {
    return get(base.prefix + '/api/v1/exam/examTypeInfos', data)
  },
  // 获取考试列表
  getExamList(data) {
    return get(base.prefix + '/api/v1/myExam/listExamByPage', data)
  },
  //新增多科或单科
  insertExam(data){
    return post(base.prefix + '/api/v1/myExam/insertExam', data)
  },
  //新增单科
  insertSimpleCourseExam(data){
    return post(base.prefix + '/api/v1/myExam/insertSubject',data)
  },
  //编辑多科
  updateExam(data){
    return post(base.prefix + '/api/v1/myExam/updateExam', data)
  },
  //编辑单科
  updateSimpleCourseExam(data){
    return post(base.prefix + '/api/v1/myExam/updateExamMany',data)
  },
  //添加/更新考试
  insertOrUpdateExam(data) {
    return post(base.prefix + '/api/v1/exam/insert', data)
  },
  //删除多科考试
  deleteSingleExam(data) {
    return post(base.prefix + '/api/v1/myExam/deleteExamById', data)
  },
  //获取科目考试详细
  getExamDetail(data) {
    // return get(base.prefix + '/api/v1/exam/getExamDetail', data)
  },  
  //获取负责人列表
  getExamManagerList(data) {
    return get(base.prefix + '/api/v1/teach/schoolTeacherInfo/school-teacher-infos-list', data)
  },

  //oss 上传base64图片
  uploadImg(data) {
    return post(base.prefix + '/api/v1/teach/oss/file/oss/upload_files', data)
  },
  saveCardTemplate(data) {
    return post(base.prefix + '/api/v1/myExamCourse/updateExamCourse', data)
  },
  getCardTemplateDetail(data) {
    return get(base.prefix + `/api/v1/myExamCourse/school-exam-course-relation-infos/${data.examId}`)
  },
  //新增切块
  insertExamCourse(data) {
    return post(base.prefix + '/api/v1/school-answer-positionings', data)
  },
  //编辑切块
  updateExamCourse(data) {
    return put(base.prefix + `/api/v1/school-answer-positionings/${data.id}`)
  },
  // 删除切块
  deleteExamCourse(data) {
    return deleteRequest(base.prefix + `/api/v1/school-answer-positionings`, data, 'arr')
  },
  //更新模版状态
  updateExamCourseStatus(data) {
    // return post(base.prefix + '/api/v1/myExamCourse/updateExamCourseStatus', data)
  },
  //获取批阅块列表
  getExamCourseList(data) {
    return get(base.prefix + `/api/v1/school-answer-paintings`, data)
  },
}
export default exam