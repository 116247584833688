import { get, post,put,deleteRequest,service1 } from '../common/http';
import base from '../common/base';
import axios from 'axios' // 引用axios

const analysis = {
  downloadModule(data){
    const url = base.prefix + `/api/v1/student/downStudent`;
        return new Promise((resolve, reject) => {
            service1.get(url, { params: data, responseType: 'blob' }).then(
                response => {
                    resolve(response)
                },
                err => {
                    reject(err)
                }
            )
        })
  },
  fileUpdate(data){
    const url = base.prefix + `/api/v1/student/importUserInfo`;
        return new Promise((resolve, reject) => {
            service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
                response => {
                    resolve(response.data)
                },
                err => {
                    reject(err)
                }
            )
        })
  },
  getScanedInfo(data) {
    return get(base.prefix + '/api/v1/student/stuInfoList', data)
  },
  //等级分设置
  scanPicAddress() {
    return base.prefix + '/api/v1/grade'
  },
  rankScoreSet(data) {
    return post(base.prefix + '/api/v1/systemSettingsRank/schoolInsertOrUpdate', data)
  },
  getRankScoreSet(data) {
    return post(base.prefix + '/api/v1/systemSettingsRank/queryList', data)
  },
  getScoreSet(data) {
    return post(base.prefix + '/api/v1/systemSettingsRank/queryListAll', data)
  },
  editLevelName(data) {
    return put(base.prefix + '/api/v1/systemSettingsRank/editLevelName', data)
  },
  editInterval(data) {
    return put(base.prefix + '/api/v1/systemSettingsRank/editInterval', data)
  },
  deleteRankScoreSetItem(data) {
    return deleteRequest(base.prefix + '/api/v1/systemSettingsRank/deleteById', data,'arr')
  },
  systemSettingsRankInsert(data) {
    return post(base.prefix + '/api/v1/systemSettingsRank/schoolInsert', data)
  },
  systemSettingsRankInsertFufen(data) {
    return post(base.prefix + '/api/v1/systemSettingsRank/schoolInsertFufen', data)
  },
  systemSettingsOneCourseRank(data) {
    return post(base.prefix + '/api/v1/systemSettingsRank/systemSettingsOneCourseRank', data)
  },
  deleteRankScoreSet(data) {
    return post(base.prefix + '/api/v1/systemSettingsRank/deleteWholeById', data)
  },
  systemSettingData(data){
    return post(base.prefix + '/api/v1/systemSettings/insertOrUpdate', data)
  },
  // 获取考试设置
  getExamSetting(data) {
    return get(base.prefix + '/api/v1/systemSettings/detailByGradeId', data)
  },
  insertExam(data){
    return post(base.prefix + '/api/v1/myExam/insertExam', data)
  },
  //新增多科
  insertMultiCourseExam(data){
    return post(base.prefix + '/api/v1/exam/analysis/analysisManyInsertOrUpdate',data)
  },
  // 新增单科
  insertSimpleCourseExam(data){
    return post(base.prefix + '/api/v1/exam/analysis/analysisOneInsertOrUpdate',data)
  },
  oneCourseScoreRateInsertOrUpdate(data){
    return post(base.prefix + '/api/v1/exam/analysis/oneCourseScoreRateInsertOrUpdate',data)
  },
  // 新增单科
  settingsManyCourseInsertOrUpdate(data){
    return post(base.prefix + '/api/v1/exam/analysis/settingsManyCourseInsertOrUpdate',data)
  },
  //考试分析列表
  getExamList(data){
    return get(base.prefix + '/api/v1/exam/analysis/list',data)
  },
  //单科分析详情
  getAnalysisDetail(data){
    return get(base.prefix + '/api/v1/exam/analysis/detailOneById/'+data.id,data)
  },
  //多科分析详情
  getManyAnalysisDetail(data){
    return get(base.prefix + '/api/v1/exam/analysis/detailManyById/'+data.id,data)
  },
  //分析详情系统设置信息
  getSystemSettings(data){
    return get(base.prefix + '/api/v1/systemSettings/list',data)
  },
  multipleRates(data){
    return get(base.prefix + '/api/v1/exam/analysis/multipleRates',data)
  },
  //分析详情系统设置科目组合管理
  getCourseSubjects(data){
    return get(base.prefix + '/api/v1/system-settings-one-course-subjects',data)
  },
  //分析详情系统设置科目组合管理增加
  addCourseSubjects(data){
    return post(base.prefix + '/api/v1/system-settings-one-course-subjects',data)
  },
  //分析详情系统设置科目组合管理编辑
  editCourseSubjects(data){
    return put(base.prefix + '/api/v1/system-settings-one-course-subjects',data)
  },
  //分析详情系统设置科目组合管理删除
  deleteCourseSubjects(data){
    return deleteRequest(base.prefix + '/api/v1/system-settings-one-course-subjects',data,'arr')
  },
  //分析详情系统设置班级组合管理
  getClassSource(data){
    return get(base.prefix + '/api/v1/system-settings-one-course-classs',data)
  },
  //分析详情系统设置班级组合管理班级查询
  getClassPageList(data){
    return get(base.prefix + '/api/v1/exam/queryListByGradeId/'+data.gradeId,data);
  },
  //分析详情系统设置班级组合管理增加
  addClassSource(data){
    return post(base.prefix + '/api/v1/system-settings-one-course-classs',data)
  },
  //分析详情系统设置班级组合管理编辑
  editClassSource(data){
    return put(base.prefix + '/api/v1/system-settings-one-course-classs/'+data.id,data)
  },
  //分析详情系统设置班级组合管理删除
  deleteClassSource(data){
    return deleteRequest(base.prefix + '/api/v1/system-settings-one-course-classs',data,'arr')
  },
  //删除多科考试
  deleteExam(data){
    return post(base.prefix + '/api/v1/exam/analysis/multidisciplinary_delete',data)
  },
  deleteSingleExam(data){
    return post(base.prefix + '/api/v1/exam/analysis/delete',data)
  },
  copyQrades(data){
    return post(base.prefix + '/api/v1/question/report/copyQrades',data)
  },
  errorCorrectionList(data){
    return get(base.prefix + '/api/v1/errorCorrectionList',data)
  },
  getGradeessInfo(data){
    return get(base.prefix + '/api/v1/school-exam-analysis-correction-gradess',data)
  },
  addGradeessInfo(data){
    return post(base.prefix + '/api/v1/school-exam-analysis-correction-gradess',data)
  },
  editGradeAnswer(data){
    return post(base.prefix + '/api/v1/updateById',data)
  },
  deleteGradeessInfo(data){
    return deleteRequest(base.prefix + '/api/v1/school-exam-analysis-correction-gradess',data,'arr')
  },
  editGradeessInfo(data){
    return post(base.prefix + '/api/v1/edit',data)
  },
  editAnswerInfo(data){
    return post(base.prefix + '/api/v1/synchronization',data)
  },
  studentReportInfo(data){
    return get(base.prefix + '/api/v1/question/report/studentReportInfo',data)
  },
  studentAnswerInfo(data){
    return put(base.prefix + '/api/v1/school-exam-analysis-course-question-student-answers/'+data.id,data)
  },
  //导入学生成绩
  importStudentScore(data){
    const url = base.prefix + `/api/v1/question/scores/importStudentInfoScore`
    return new Promise((resolve, reject) => {
      service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
        response => {
          resolve(response.data)
        },
        err => {
          reject(err)
        }
      )
    })
  },
  //追加导入学生成绩
  importAddStudentScoreInfo(data){
    const url = base.prefix + `/api/v1/question/scores/importAddStudents`
    return new Promise((resolve, reject) => {
      service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
        response => {
          resolve(response.data)
        },
        err => {
          reject(err)
        }
      )
    })
  },
  getQeustionScoreList(data){
    return get(base.prefix + '/api/v1/question/scores/queryListByPage',data)
  },
  //获取模版编辑列表
  getTemplateList(data){
    return get(base.prefix + '/api/v1/templateEdit/list',data)
  },
  //bian编辑模版
  updateTemplate(data){
    return post(base.prefix + '/api/v1/templateEdit/updateById',data)
  },
  //编辑模版导出
  templateEditingExport(data){
    const url = base.prefix + '/api/v1/templateEdit/templateEditingExport';
    return new Promise((resolve, reject) => {
      service1.post(url, data, {responseType: 'blob' }).then(
          response => {
              resolve(response)
          },
          err => {
              reject(err)
          }
      )
  })
  },
  //编辑模版导入
  templateEditingImport(data){
    const url = base.prefix + '/api/v1/templateEdit/templateEditingImport';
    return new Promise((resolve, reject) => {
      service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
        response => {
          resolve(response.data)
        },
        err => {
          reject(err)
        }
      )
    })
  },
  //模版删除
  deleteTemplateItem(data){
    return deleteRequest(base.prefix + '/api/v1/school-exam-analysis-course-question-titles',data,'arr')
  },
  //导入题目分组
  importQuestionGroup(data){
    const url = base.prefix + `/api/v1/group/importGroupInfo`
    return new Promise((resolve, reject) => {
      service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
        response => {
          resolve(response.data)
        },
        err => {
          reject(err)
        }
      )
    })
  },
  //导出题目分组
  exportQuestionGroup(data){
    const url = base.prefix + `/api/v1/group/exportGroupInfo`;
        return new Promise((resolve, reject) => {
            service1.get(url, { params: data, responseType: 'blob' }).then(
                response => {
                    resolve(response)
                },
                err => {
                    reject(err)
                }
            )
        })
  },
  getQuestionGroupList(data){
    return get(base.prefix + '/api/v1/group/list',data)
  },
  updateQuestionGroup(data){
    return post(base.prefix + '/api/v1/group/updateById',data)
  },
  //考场名单
  getExamStudentList(data){
    return get(base.prefix + '/api/v1/exam/nameOrder/queryListByPage',data)
  },
  getExamStudentListPage(data){
    return get(base.prefix + '/api/v1/exam/nameOrder/multipleQueryListByPage',data)
  },
  //考场名单
  deleteStudentList(data){
    return deleteRequest(base.prefix + '/api/v1/exam/nameOrder/delete',data,'arr')
  },
  //考场名单新增
  adminInsert(data){
    return post(base.prefix + '/api/v1/exam/nameOrder/adminInsert',data,'arr')
  },
  manyCourseScoreRateUpdate(data){
    return post(base.prefix + '/api/v1/exam/analysis/manyCourseScoreRateUpdate',data,'arr')
  },
  //导入考场名单
  importExamStudentList(data){
    const url = base.prefix + `/api/v1/exam/nameOrder/importStudentExamNameListInfo`;
        return new Promise((resolve, reject) => {
            service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
                response => {
                    resolve(response.data)
                },
                err => {
                    reject(err)
                }
            )
        })
  },
  //导出考场名单
  exportExamStudentList(data){
    const url = base.prefix + `/api/v1/exam/nameOrder/exportStudentExamNameListInfo`;
        return new Promise((resolve, reject) => {
            service1.get(url, { params: data, responseType: 'blob' }).then(
                response => {
                    resolve(response)
                },
                err => {
                    reject(err)
                }
            )
          })
  },
  //学年获取年级
  getGradeList(data){
    return get(base.prefix + '/api/v1/teach/schoolGradeRelation/schoolAllGradeListByYearId',data)
  },
  //根据学期id获取科目
  getCourseInfo(){
    return get(base.prefix + '/api/v1/teach/schoolCourseRelation/schoolQueryCourseInfoListBySemesterId/'+data.semesterId,data)
  },
  //考场名单编辑
  updateExamStudentList(data){
    return post(base.prefix + '/api/v1/exam/nameOrder/update',data)
  },
  queryGradeLeader(data){
    return post(base.prefix + '/api/v1/exam/analysis/queryGradeLeader',data)
  },
  //分数段统计
  getScoreRangeList(data){
    return get(base.prefix + '/api/v1/question/scores/analysisCourseQuestionScoreRankingSection',data)
  },
  //考场名单详情
  getOneById(data){
    return get(base.prefix + '/api/v1/exam/nameOrder/getOneById',data)
  },
  queryClassInfo(data){
    return get(base.prefix + '/api/v1/exam/nameOrder/queryClassInfo',data)
  },
}

export default analysis