import { get, post, put, deleteRequest,service1} from '../common/http';
import base from '../common/base';
import axios from 'axios' // 引用axios
const school = {
      // 获取教学班
      getJXB (data) {
        return get(base.prefix + '/api/v1/teach/schoolClass/queryListBySemesterId/' +  data.gradeId)
      },
    // 获取行政班接口
    getStudentInfoList (data) {
      return get(base.prefix + '/api/v1/student/studentList',data)
    },
  // 获取行政班接口
  getXZB (data) {
    return get(base.prefix + '/api/v1/grade/administration',data)
  },
  // 新增学生
  addStudent (data) {
    return post(base.prefix + '/api/v1/student/addUser',data)
  },
  undatePic (data) {
    return put(base.prefix + '/api/v1/scanned/updateOrder',data)
  },
  getPiciOrExam (data) {
    return get(base.prefix + '/api/v1/scanned/classList',data)
  },
  getExamItem (data) {
    return get(base.prefix + '/api/v1/student/scannedList',data)
  },
  getExamList (data) {
    return get(base.prefix + '/api/v1/student/examList',data)
  },
  deleteExamAll(data) {
    return deleteRequest(base.prefix + '/api/v1/student/delStudent',data)
  },
  getExamScanLIst(data) {
    return get(base.prefix + '/api/v1/student/sdList',data)
  },
  getExamInfo(data) {
    return post(base.prefix + '/api/v1/student/addScanned',data)
  },
  getSysList(data) {
    return get(base.prefix + '/api/v1/student/scList',data)
  },
  getSchoolGradeCourse() {
    return get(base.prefix + '/api/v1/teach/schoolCourseRelation/schoolAllCourseList')
  },
  getSchoolGradeAllList() {
    return get(base.prefix + '/api/v1/getAllList')
  },
  deleteGredeCourse(data) {
    return get(base.prefix + '/api/v1/teach/schoolCourseRelation/deleteCourse/'+data.id)
  },
  getGradeCourseAllRelation() {
    return get(base.prefix + '/api/v1/teach/schoolCourseRelation/schoolQueryGradeCourseAllRelation')
  },
  setGradeCourseInfo(data){
    return post(base.prefix + '/api/v1/teach/schoolCourseRelation/schoolSetGradeCourseInfo',data)
  },
  //学年学期列表
  getGradeYearTearm(data){
    return get(base.prefix + '/api/v1/teach/schoolYear/schoolList',data);
  },
  //编辑学年
  setGradeYear(data){
    return post(base.prefix + '/api/v1/teach/schoolYear/updateById', data);
  },
  //删除学年
  deleteGradeYear(data){
    return deleteRequest(base.prefix + '/api/v1/teach/schoolYear/deleteByIds/'+data.id);
  },
  // 增加学期
  addTerm(data){
    return post(base.prefix + '/api/v1/schoolSemester/schoolAddSemesterInfo',data)
  },
  //编辑学期
  editTerm(data){
    return put(base.prefix + '/api/v1/schoolSemester/updateById',data)
  },
  //删除学期
  deleteTerm(data){
    return post(base.prefix + '/api/v1/schoolSemester/deleteByIds',data)
  },
  //年级列表
  getGradeYear(){
    return get(base.prefix + '/api/v1/teach/schoolGradeRelation/adminAllGradeList')
  },
  //班级列表 树形结构
  getClassTree(data){
    return get(base.prefix + '/api/v1/teach/schoolClass/queryListBySemesterId/'+ data.semesterId)
  },
  getStudentList(data){
    return get(base.prefix + '/api/v1/teach/schoolStudentClassRelation/classStudentList', data)
  },
  getDetailById(data){
    return get(base.prefix + '/teaching/api/v1/teach/schoolStudentClassRelation/detailById/'+ data.id)
  },
  //老师列表
  getTeacherList(data){
    return get(base.prefix + '/api/v1/teach/user/listTeacherALlUser', data)
  },
  //课程表 行政班列表
  getCourseAdminClassList(data){
    return get(base.prefix + '/api/v1/teach/schoolTimeTable/querySchoolTimeTable/'+ data.semesterId)
  },
  addClass(data){
    return post(base.prefix + '/api/v1/teach/schoolClass/add', data)
  },
  editClass(data){
    return post(base.prefix + '/api/v1/teach/schoolClass/updateById', data)
  },
  deleteClass(data){
    return post(base.prefix + '/api/v1/teach/schoolClass/deleteByIds', data)
  },
  //新增学生
  addStudent(data){
    return post(base.prefix + '/api/v1/teach/schoolStudentClassRelation/adminInsertOrUpdate', data)
  },
  //编辑学生
  editStudent(data){
    return post(base.prefix + '/api/v1/teach/schoolStudentClassRelation/adminInsertOrUpdate', data)
  },
  //删除学生
  deleteStudent(data){
    return post(base.prefix + '/api/v1/teach/schoolStudentClassRelation/deleteById/'+data.id)

  },
  //密码重置 
  resetPasswordToClass(data){
    return put(base.prefix + '/api/v1/teach/user/resetPassword', data)
  },
  resetPasswordToStudent(data){
    return put(base.prefix + '/api/v1/teach/user/resetPassword', data)
  },
  //导入学生
  importStudent(data){
    return post(base.prefix + '/api/v1/teach/schoolStudent/importStudent', data)
  },
  //学校所有科目
  getGradeAllCourse(data){
    return get(base.prefix + '/api/v1/teach/schoolCourseRelation/schoolQueryCourseList', data)
  },
  // 教师绑定角色
  teacherBindRole(data){
    return post(base.prefix + '/api/v1/teach/schoolTimeTable/addSchoolTeacherIdentityRelation', data)
  },
  deleteTeacherBindRole(data){
    return post(base.prefix + '/api/v1/teach/schoolTimeTable/deleteByIds', data)
  },
  addTeacher(data){
    return post(base.prefix + '/api/v1/teach/user/adminInsertTeacher', data)
  },
  deleteTeacher(data){
    return post(base.prefix + '/api/v1/teach/user/deleteTeacherByIds', data)
  },
  getTeacherDetail(data){
    return get(base.prefix + '/api/v1/teach/user/teacherDetailById/'+data.id)
  },
  editTeacher(data){
    return put(base.prefix + '/api/v1/teach/user/updateTeacher', data)
  },
  updateTeacherPassWord(data){
    return put(base.prefix + '/api/v1/teach/user/resetPassword', data)
  },
  getSectionClassCourse(data){
    return get(base.prefix + '/api/v1/teach/schoolTimeTable/querySchoolTeachTimeTable/'+ data.semesterId)
  },
  getRolePersonList(data){
    return get(base.prefix + '/api/v1/teach/schoolTimeTable/schoolQueryPersonInfo',data)
  },
  getManaPersonList(data){
    return get(base.prefix + '/api/v1/teach/schoolTimeTable/SchoolList',data)
  },
  getRoleList(){
    return get(base.prefix + '/api/v1/teach/schoolTeacher/queryAllList')
  },
  getSchoolQueryPersonRole(data){
    return get(base.prefix + '/api/v1/teach/schoolTimeTable/schoolQueryPersonRole',data)
  },
  getStudentRegistType(){
    return get(base.prefix + '/api/v1/studentRegistration/schoolQueryList')
  },
  getStudenntInfo(data){
    return get(base.prefix + '/api/v1/teach/schoolStudentClassRelation/detailById',data)
  },
  //get role  
  getTeacherRole(){
    return get(base.prefix + '/api/v1/teach/schoolTeacher/queryList')
  },
  setTeacherRole(data){
    return post(base.prefix + '/api/v1/teach/schoolTeacherInfo/addOrUpdate',data)
  },
  uploadStudentInfo(data){
    const url = base.prefix + `/api/v1/teach/schoolStudentClassRelation/importStudentInfo`
    return new Promise((resolve, reject) => {
      service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
        response => {
          resolve(response.data)
        },
        err => {
          reject(err)
        }
      )
    })
  },
  //导出文件
  exportStudentInfo(data){
    const url = base.prefix + `/api/v1/teach/schoolStudentClassRelation/exportStudentInfo`;
        return new Promise((resolve, reject) => {
            service1.get(url, { params: data, responseType: 'blob' }).then(
                response => {
                  console.log(response,"response--=-")
                    resolve(response)
                },
                err => {
                    reject(err)
                }
            )
        })
  },
  //任课教师导出
  exportTeacherInfos(data){
    const url = base.prefix + `/api/v1/teach/schoolTimeTable/exportTeacherInfo`;
        return new Promise((resolve, reject) => {
            service1.get(url, { params: data, responseType: 'blob' }).then(
                response => {
                  console.log(response,"response--=-")
                    resolve(response)
                },
                err => {
                    reject(err)
                }
            )
        })
  },
  //任课关系列表查询
  getTeacherClassList(data){
    return get(base.prefix + '/api/v1/teach/schoolTimeTable/queryTeachingList',data)
  },
  //任课关系列表查询
  teacherClassDetailsList(data){
    return get(base.prefix + '/api/v1/teach/schoolTimeTable/TeacherClassDetailsList',data)
  },
  // 我的管理权限查询
  getTeacherMananList(data){
    return get(base.prefix + '/api/v1/teach/schoolTimeTable/teacherAuthManagerList',data)
  },
  importTeacherList(data){
    const url = base.prefix + `/api/v1/teach/schoolTeacherInfo/importTeacherInfo`
    return new Promise((resolve, reject) => {
      service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
        response => {
          resolve(response.data)
        },
        err => {
          reject(err)
        }
      )
    })
  },
  exportTeacherList(data){
    const url = base.prefix + `/api/v1/teach/schoolTeacherInfo/exportTeacherInfo`
    return new Promise((resolve, reject) => {
      service1.get(url, { params: data, responseType: 'blob' }).then(
        response => {
          resolve(response)
        },
        err => {
          reject(err)
        }
      )
    })
  },
  resetStudentsClassPassWord(data){
    return post(base.prefix + '/api/v1/teach/schoolStudentClassRelation/resetClassStudentUserPwd',data)
  },
  addSchoolYear(data){
    return post(base.prefix + '/api/v1/teach/schoolYear/addSchoolYear',data)
  },
  //用户管理学生名单查询
  getSchoolUserInfos(data) {
    return get(base.prefix + '/api/v1/teach/user/listStudentUser',data)
  },
  //用户管理教师名单查询
  listTeacherUser(data) {
    return get(base.prefix + '/api/v1/teach/user/listTeacherUser',data)
  },
  //班级名单新增学生
  adminInsertStudent(data){
    return post(base.prefix + '/api/v1/teach/user/adminInsertStudent', data)
  },
  //用户管理新增学生
  addUserStudent(data){
    return post(base.prefix + '/api/v1/teach/user/add', data)
  },
  //用户管理新增教师
  adminInsertTeacher(data){
    return post(base.prefix + '/api/v1/teach/user/adminInsertTeacher', data)
  },
  //班级名单编辑学生
  updateStudent(data){
    return put(base.prefix + '/api/v1/teach/user/updateStudent', data)
  },
  //用户管理编辑学生
  updateStudentById(data){
    return put(base.prefix + '/api/v1/teach/user/updateById/'+data.id,data)
  },
  //用户管理学生批量重置密码
  batchResetPassword(data){
    return put(base.prefix + '/api/v1/teach/user/batchResetPassword', data)
  },
  //用户管理编辑教师
  updateTeacher(data){
    return put(base.prefix + '/api/v1/teach/user/updateTeacher', data)
  },
  //用户管理删除学生
  deleteStudentByIds(data){
    return post(base.prefix + '/api/v1/teach/user/deleteStudentByIds', data)
  },
  //用户管理删除教师
  deleteTeacherByIds(data){
    return post(base.prefix + '/api/v1/teach/user/deleteTeacherByIds', data)
  },
  //用户管理单独绑定邮箱
  bindCurrentUserEmail(data){
    return post(base.prefix + '/api/v1/teach/user/bindCurrentUserEmail', data)
  },
  //用户管理单独绑定邮箱校验
  checkEmailCode(data){
    return post(base.prefix + '/api/v1/teach/user/checkEmailCode', data)
  },
  //用户管理重置其他用户密码
  resetPassword(data){
    return put(base.prefix + '/api/v1/teach/user/resetPassword', data)
  },
  //用户管理批量设置用户单独密码
  updateBatchPassword(data){
    return post(base.prefix + '/api/v1/teach/user/updateBatchPassword', data)
  },
  //用户管理批量解绑邮箱
  batchUnBindUserEmail(data){
    return post(base.prefix + '/api/v1/teach/user/batchUnBindUserEmail', data)
  },
  //用户管理批量绑定邮箱
  batchBindUserEmail(data){
    return post(base.prefix + '/api/v1/teach/user/batchBindUserEmail', data)
  },
  //用户管理单个用户启用禁用
  updateStatusEnable(data){
    return post(base.prefix + '/api/v1/teach/user/updateStatusEnable', data)
  },
  //用户管理批量启用禁用
  batchUpdateStatusEnable(data){
    return post(base.prefix + '/api/v1/teach/user/batchUpdateStatusEnable', data)
  },
  //用户管理教师批量导入
  importTeacherInfo(data){
    let url=base.prefix + '/teaching/api/v1/teach/schoolTeacherInfo/importTeacherInfo'
    return new Promise((resolve, reject) => {
      service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
        response => {
          resolve(response.data)
        },
        err => {
          reject(err)
        }
      )
    })
  },
  //用户管理学生批量导入
  importStudentInfo(data){
    let url=base.prefix + '/api/v1/teach/user/importStudentInfo'
    return new Promise((resolve, reject) => {
      service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
        response => {
          resolve(response.data)
        },
        err => {
          reject(err)
        }
      )
    })
  },
  //用户管理批量教师导出
  exportTeacherInfo(data){
    let url = base.prefix + '/teaching/api/v1/teach/schoolTeacherInfo/exportTeacherInfo';
    return new Promise((resolve, reject) => {
      service1.get(url, { params: data, responseType: 'blob' }).then(
        response => {
          resolve(response)
        },
        err => {
          reject(err)
        }
      )
    })
  },
  //用户管理批量学生导出
  exportUserInfo(data){
    let url = base.prefix + '/api/v1/teach/user/exportUserInfo';
    return new Promise((resolve, reject) => {
      service1.get(url, { params: data, responseType: 'blob' }).then(
        response => {
          resolve(response)
        },
        err => {
          reject(err)
        }
      )
    })
  },
  //学生名单学生模板下载
  exportTemplate(data){
    let url = base.prefix + '/api/v1/teach/schoolStudentClassRelation/exportTemplate';
    return new Promise((resolve, reject) => {
      service1.get(url, { params: data, responseType: 'blob' }).then(
        response => {
          resolve(response)
        },
        err => {
          reject(err)
        }
      )
    })
  },
}
export default school