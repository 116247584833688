import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import getters from './getters'
import user from './modules/user'
import adminUser from './modules/adminUser'
import analysis from './modules/analysis'
import makeSheet from './modules/makeSheet'
import markPaper from './modules/markPaper'
import makeTemplate from './modules/makeTemplate'
Vue.use(Vuex)
// const vuexPersisted = new createPersistedState({
//   storage: {
//       getItem: key => localStorage.getItem(key),
//       setItem: (key, value) => localStorage.setItem(key, value),
//       removeItem: key => localStorage.removeItem(key)
//   }
// })
const store = new Vuex.Store({
  modules: {
    user,
    makeSheet,
    analysis,
    markPaper,
    adminUser,
    makeTemplate
  },
  getters,
  plugins: [createPersistedState()]
})

export default store
