const getters = {
  token: state => state.user.token,
  adminToken: state => state.adminUser.adminToken,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  currentId: state => state.product.currentId,
  roleId: state => state.user.roleId,
  buttons: state => state.user.buttons,
  userInfo: state=>state.user.userInfo,
  analyInfo:state=>state.analysis.analyInfo,
  drawScoreList:state=>state.markPaper.drawScoreList,
  scoreValText: state=>state.markPaper.scoreValText,
  
}
export default getters
