const state = {
  drawScoreList:[], //画在试卷的分数列表
  scoreValText:'', //分数文本框的值
}

const mutations = {
  SET_drawScoreList: (state, data) => {
    state.drawScoreList.push(data);
    let txt = '';
    state.drawScoreList.forEach((item)=>{txt+= String(item.score)})
    console.log(txt)
    state.scoreValText  = txt;
  },
  DEL_drawScoreList: (state) => {
    state.drawScoreList.pop();
    let txt = '';
    state.drawScoreList.forEach((item)=>{txt+= String(item.score)})
    state.scoreValText  = txt;
  },
  INIT_DrawScoreList: (state, data) => {
    state.drawScoreList = [];
    state.scoreValText  = '';
  },
}

const actions = {
  deleteDrawScoreList({ commit }) {
    commit("DEL_drawScoreList")
  },
  initDrawScoreList({ commit }) {
    commit("INIT_DrawScoreList")
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}