// 写一个防抖函数
export function debounce(fn, delay) {
    let timer = null
    return function () {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn.apply(this, arguments)
        }, delay)
    }
}

//节流函数
export function throttle(fn, delay) {
    let timer = null
    return function () {
        if (timer) {
            return
        }
        timer = setTimeout(() => {
            fn.apply(this, arguments)
            timer = null
        }, delay)
    }
}

export function getDPI() {

    var arrDPI = new Array;

    if (window.screen.deviceXDPI) {

        arrDPI[0] = window.screen.deviceXDPI;

        arrDPI[1] = window.screen.deviceYDPI;

    } else {
        

        var tmpNode = document.createElement("DIV");

        tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";

        document.body.appendChild(tmpNode);

        arrDPI[0] = parseInt(tmpNode.offsetWidth);

        arrDPI[1] = parseInt(tmpNode.offsetHeight);

        tmpNode.parentNode.removeChild(tmpNode);

    }
    console.log(arrDPI)
    return arrDPI;

}

//px转毫米

export function pxToMm(value) {

    var inch = value / getDPI()[0];

    var c_value = inch * 25.4;

    return c_value;

}

//毫米转px

// export function mmToPx(value) {

//     var inch = value / 25.4;

//     var c_value = inch * getDPI()[0];

//     return c_value;

// }


//动态获取本地图片
export function getLocalImage(image){
    return new URL(`../../../assets/qrcode/${image}`, import.meta.url).href;
}

//数组去重
export function unique(arr) {
    return Array.from(new Set(arr))
}

//获取当前年
export function getYear() {
    return new Date().getFullYear()
}

//保留俩位小数
export function fixNumber(num) {
    return Number(num.toFixed(2))
}

//保留整数 小数 四舍五入
export function mmToPx(num) {
    return  Math.round(1754/297*num) //高 150dpi 1754  96dpi:1120         1754*2480
}