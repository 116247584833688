import { get, post,put,service1 } from '../common/http';
import base from '../common/base';
import axios from 'axios' // 引用axios
const analysisReport = {
  getAnalysisCategory(data){
    return get(`${base.prefix}/api/v1/question/report/queryHeadParamInfo`,data)
  },
  //成绩单列表
  getStudentScoreList(data){
    return get(`${base.prefix}/api/v1/question/report/studentReportInfo`,data)
  },
  exportReport(data){
    const url = base.prefix + `/api/v1/question/report/exportReport`;
        return new Promise((resolve, reject) => {
            service1.get(url, { params: data, responseType: 'blob' }).then(
                response => {
                    resolve(response)
                },
                err => {
                    reject(err)
                }
            )
        })
  },
  //成绩小条
  getExamStudentScoreList(data) {
    return get(`${base.prefix}/api/v1/question/report/studentScoreReportInfo`,data)
  },
  //分数段汇总信息接口
  getScoreSectionInfo(data) {
    return get(`${base.prefix}/api/v1/question/scores/analysisCourseQuestionScoreSection`,data)
  },
}
export default analysisReport;